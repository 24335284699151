import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FormProps from "./FormProps";

const Form = loadable(
    (): Promise<DefaultComponent<FormProps>> =>
        import(/* webpackChunkName: "Form" */ "./Form"),
);
const FormLoader = (props: FormProps): ReactElement => <Form {...props} />;
export default FormLoader;
