import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import MapProps from "./MapProps";

const Map = loadable(
    (): Promise<DefaultComponent<MapProps>> =>
        import(/* webpackChunkName: "Map" */ "./Map"),
);
const MapLoader = (props: MapProps): ReactElement => <Map {...props} />;
export default MapLoader;
