import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ImageProps from "./ImageProps";

const Image = loadable(
    (): Promise<DefaultComponent<ImageProps>> =>
        import(/* webpackChunkName: "Image" */ "./Image"),
);
const ImageLoader = (props: ImageProps): ReactElement => <Image {...props} />;
export default ImageLoader;
