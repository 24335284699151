import { styled, css } from "styled-components";
import theme from "styled-theming";

import { Container as BlockQuote } from "components/BlockQuote/BlockQuote.styled";
import { StyledLink, embedButtonStyle } from "components/Button/Button.styled";
import { Container as FactBox } from "components/FactBox/FactBox.styled";
import {
    inlineImage,
    StyledButton as ImageButton,
} from "components/Image/Image.styled";
import { Image } from "components/ImageSection/ImageSection.styled";
import { styledLink } from "components/Link/Link.styled";
import { styledListUL, styledListOL } from "components/List/List.styled";
import { styledTable, responsiveTable } from "components/Table/Table.styled";
import { VideoWrapper as Video } from "components/Video/Video.styled";
import {
    styleBodyLAlt,
    styleHeadingL,
    styleHeadingM,
    styleHeadingS,
} from "style/components/Typography";
import { columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Container = styled.div.attrs<{
    $displayOption?: string;
    $editorialGrid?: boolean;
}>(() => ({}))`
    & > div {
        &:first-child > div:first-child > :first-child {
            margin-top: 0;
        }

        &:last-child :last-child {
            margin-bottom: 0;
        }

        ${(props) =>
            props.$editorialGrid &&
            css`
                ${editorialGrid}
            `}

        & > ${StyledLink} {
            margin-bottom: 0.875rem;
            margin-right: auto;
            margin-top: 1.75rem;

            ${MQ.FROM_M} {
                margin-top: 2.1875rem;
            }

            ${MQ.FROM_XL} {
                margin-top: 2.625rem;
            }
        }

        /* stylelint-disable-next-line no-duplicate-selectors */
        & > ${Image}, & > ${Video}, & > ${BlockQuote}, & > ${ImageButton} {
            margin: 2.625rem auto 0.875rem 0;

            ${MQ.FROM_M} {
                margin: 3.5rem auto 1.75rem 0;
            }

            ${MQ.FROM_XL} {
                margin: 4.375rem auto 2.625rem 0;
            }
        }

        /* stylelint-disable-next-line */
        ${FactBox} + ${FactBox} {
            margin-top: 0;
        }
    }

    ${styledListOL}

    ${styledListUL}

    .responsive-table {
        ${responsiveTable}
    }

    table {
        ${styledTable}
    }

    img {
        ${inlineImage}
    }

    .intro {
        display: block;
        ${styleBodyLAlt}
    }

    a:not([class*="embedded"], [class*="button"], [class*="hoverspan"]) {
        ${styledLink}
    }

    button.button,
    a.button {
        ${embedButtonStyle}
    }

    h2 {
        ${styleHeadingL}
        margin-bottom: 2.1875rem;
        margin-top: 3.5rem;

        ${MQ.FROM_M} {
            margin-bottom: 2.625rem;
            margin-top: 4.375rem;
        }

        ${MQ.FROM_XL} {
            margin-bottom: 3.5rem;
            margin-top: 6.125rem;
        }
    }

    h3 {
        ${styleHeadingM}
        margin-bottom: 1.75rem;
        margin-top: 2.1875rem;

        ${MQ.FROM_M} {
            margin-bottom: 2.1875rem;
            margin-top: 2.625rem;
        }

        ${MQ.FROM_XL} {
            margin-bottom: 2.625rem;
            margin-top: 3.5rem;
        }
    }

    h4 {
        ${styleHeadingS}
        margin-bottom: 0;
        margin-top: 1.75rem;
    }

    h4 + p {
        margin-top: 0;
    }

    p {
        margin-bottom: 1.75rem;
        margin-top: 1.75rem;
    }

    hr {
        width: 100%;
        border: none;
        border-top: 1px solid
            var(
                ${theme("theme", {
                    undefined: "--line-on-neutral-default",
                    white: "--line-on-neutral-default",
                    lightgray: "--line-on-neutral-default",
                    blue: "--line-on-blue-default",
                    cyan: "--line-on-cyan-default",
                })}
            );
    }
`;

export const editorialGrid = css`
    ${columnGap}
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(4, [col-start] 1fr);

    ${MQ.FROM_M} {
        grid-template-columns: repeat(8, [col-start] 1fr);
    }

    ${MQ.FROM_XL} {
        grid-template-columns: repeat(7, [col-start] 1fr);
    }

    & > * {
        grid-column: 1 / span 4;

        ${MQ.FROM_M} {
            grid-column: 1 / span 8;
        }

        ${MQ.FROM_L} {
            grid-column: 1 / span 6;
            grid-column: ${theme("blockInBlock", {
                true: "1 / span 8"
            })}
        }

        ${MQ.FROM_XL} {
            grid-column: 1 / span 5;
            grid-column: ${theme("blockInBlock", {
                true: "1 / span 7"
            })}
        }
    }

    /* stylelint-disable-next-line no-duplicate-selectors */
    & > ${Video}, & > ${Image}, & > ${BlockQuote}, & > ${ImageButton} {
        ${MQ.FROM_L} {
            grid-column: 1 / span 8;
        }

        ${MQ.FROM_XL} {
            grid-column: 1 / span 7;
        }
    }
`;
