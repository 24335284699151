import { styled, css } from "styled-components";
import theme from "styled-theming";

import Button from "components/Button";
import { Wrapper as ButtonWrapper } from "components/Button/Button.styled";
import Form from "components/Form";
import { FormElements } from "components/Form/Form.styled";
import { styledLink } from "components/Link/Link.styled";
import Map from "components/Map";
import TextBlock from "components/TextBlock";
import { Container as TextBlockContainer } from "components/TextBlock/TextBlock.styled";
import Video from "components/Video";
import { VideoContainer } from "components/Video/Video.styled";
import { Container as GridContainer } from "style/components/Page";
import {
    styleBodyL,
    styleBodyLAlt,
    styleHeadingXXL,
    styleHeadingXXLAlt,
} from "style/components/Typography";
import { columnGap } from "style/grid";
import { headerSizes } from "style/headerOffsets";
import { MQ } from "style/mediaQueries";

const color = theme("theme", {
    black: "--text-on-image-primary",
    lightgray: "--text-on-neutral-primary",
    cyan: "--text-on-cyan-primary",
    blue: "--text-on-blue-primary",
});

export const Container = styled(GridContainer).attrs<{
    $showArrowdown: boolean;
}>(() => ({}))`
    ${columnGap}
    display: grid;
    ${(props) =>
        props.$showArrowdown
            ? css`
                  grid-template:
                      "heading" auto
                      "introduction" auto
                      "introductionHtml" auto
                      "col" auto
                      "readMore" 1fr;
              `
            : css`
                  grid-template:
                      "heading" auto
                      "introduction" auto
                      "introductionHtml" auto
                      "col" 1fr;
              `};
    grid-template-columns: repeat(4, [col-start] 1fr);
    padding-bottom: ${(props) => (props.$showArrowdown ? "0" : "2.625rem")};
    padding-top: 7rem;
    position: relative;
    ${theme("variant", {
        map: css`
            height: min(calc(100vh - 3.125rem), 29.6875rem);
        `,
        image: css`
            height: calc(100vh - 9.375rem);
            min-height: 50vh;
        `,
        full: css`
            min-height: 100vh;
        `,
        video: css`
            aspect-ratio: 16 / 9;
            max-height: calc(100vh - 70px);
        `,
        edit: css`
            min-height: 31.25rem;
        `,
    })};

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
        padding-bottom: ${(props) => (props.$showArrowdown ? "0" : "3.5rem")};
        padding-top: 10.5rem;
        ${theme("variant", {
            image: css`
                height: calc(100vh - 12.5rem);
            `,
        })};
    }

    ${MQ.FROM_L} {
        ${(props) =>
            props.$showArrowdown
                ? css`
                      grid-template:
                          "heading col" auto
                          "introduction col" auto
                          "introductionHtml col" auto
                          "readMore readMore" 1fr;
                  `
                : css`
                      grid-template:
                          "heading col" auto
                          "introduction col" auto
                          "introductionHtml col" 1fr;
                  `};
        grid-template-columns: repeat(12, [col-start] 1fr);
    }

    ${MQ.FROM_XL} {
        padding-bottom: ${(props) =>
            props.$showArrowdown ? "0" : "4.8125rem"};
        padding-top: 14.4375rem;
        ${theme("variant", {
            image: css`
                height: calc(100vh - 18.75rem);
            `,
        })};
    }
`;

export const HeroVideo = styled(Video)`
    inset: 0;
    margin-top: ${headerSizes.S};
    position: absolute;
    z-index: 1;

    ${MQ.FROM_M} {
        margin-top: ${headerSizes.M};
    }

    ${MQ.FROM_XL} {
        margin-top: ${headerSizes.XL};
    }

    ${VideoContainer} {
        bottom: 0;
        height: 31.25rem;
        overflow: visible;
        padding-bottom: 0;
        position: absolute;
        top: 0;
    }
`;

export const HeroMap = styled(Map)`
    inset: 0;
    margin-top: ${headerSizes.S};
    position: absolute;
    z-index: 1;

    ${MQ.FROM_M} {
        margin-top: ${headerSizes.M};
    }

    ${MQ.FROM_XL} {
        margin-top: ${headerSizes.XL};
    }
`;

export const Heading = styled.h1<HeadingProps>`
    ${(props) =>
        props.size && props.size === "XL"
            ? css`
                  ${styleHeadingXXLAlt}
              `
            : css`
                  ${styleHeadingXXL}
              `};
    color: var(${color});
    grid-area: heading;
    grid-column: col-start 1 / span 4;
    margin: 0;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 12;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 1 / span 8;
    }
`;

const introductionStyle = css`
    color: var(${color});
    margin-bottom: 0;
    margin-top: 1.75rem;
    ${theme("theme", {
        black: css`
            filter: var(--shadow-on-image);
        `,
    })};

    a {
        ${styledLink}
    }

    ${MQ.FROM_M} {
        grid-column: col-start 3 / span 10;
        margin-top: 2.625rem;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 2 / span 7;
    }

    ${MQ.FROM_XL} {
        margin-top: 3.5rem;
    }
`;

export const Introduction = styled.p`
    ${styleBodyLAlt}
    ${introductionStyle}
    grid-area: introduction;
    grid-column: col-start 1 / span 4;
`;

export const IntroductionHtml = styled(TextBlock)`
    ${styleBodyL}
    ${introductionStyle}
    grid-area: introductionHtml;
    grid-column: col-start 1 / span 4;

    & > div > div > p:first-child {
        ${styleBodyLAlt}
    }
`;

export const Col = styled.div`
    ${columnGap}
    display: grid;
    grid-area: col;
    grid-column: col-start 1 / span 4;
    grid-template-columns: repeat(4, [col-start] 1fr);
    padding-top: 2.625rem;
    row-gap: 2.625rem;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 12;
        grid-template-rows: 1fr;
        padding-top: 3.5rem;
        row-gap: 3.5rem;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 9 / span 4;
        grid-template-rows: auto 1fr;
        padding-top: 0.875rem;
    }
`;

export const CallToActions = styled.div`
    ${columnGap}
    display: grid;
    grid-column: auto / span 4;
    grid-template-columns: 1fr;
    row-gap: 3.125rem;

    ${MQ.FROM_M} {
        grid-template-columns: 1fr 1fr;
    }

    ${MQ.FROM_L} {
        grid-template-columns: 1fr;
    }

    ${MQ.FROM_XL} {
        grid-column: 2 / span 3;
    }
`;

export const StyledForm = styled(Form)`
    && {
        grid-column: auto / span 4;
        margin-top: 0;
        padding-bottom: 0;

        ${FormElements} {
            ${columnGap}
            row-gap: 0.875rem;
            color: var(${color});

            ${MQ.FROM_M} {
                row-gap: 1.3125rem;
                grid-template-columns: repeat(12, [col-start] 1fr);
            }

            ${MQ.FROM_L} {
                grid-template-columns: repeat(6, [col-start] 1fr);
            }

            ${MQ.FROM_XL} {
                row-gap: 1.75rem;
            }
        }

        ${TextBlockContainer} {
            margin-top: 0;
        }

        ${ButtonWrapper} {
            margin-top: 0;

            button {
                align-self: stretch;
            }
        }
    }
`;

export const Logo = styled.img`
    grid-column: auto / span 4;
    height: auto;
    justify-self: center;
    max-height: 3.5rem;
    max-width: 100%;
    width: auto;

    ${MQ.FROM_M} {
        justify-self: flex-start;
        max-height: 4.375rem;
    }

    ${MQ.FROM_L} {
        max-height: 5.25rem;
    }

    ${MQ.FROM_XL} {
        max-height: 6.125rem;

        ${theme("hasCTA", {
            true: css`
                grid-column: 2 / span 3;
            `,
        })};
    }

    ${theme("format", {
        portrait: css`
            max-height: 7rem;

            ${MQ.FROM_M} {
                max-height: 8.75rem;
            }

            ${MQ.FROM_L} {
                max-height: 10.5rem;
            }

            ${MQ.FROM_XL} {
                max-height: 12.25rem;
            }
        `,
        square: css`
            max-height: 5.25rem;

            ${MQ.FROM_M} {
                max-height: 5.6875rem;
            }

            ${MQ.FROM_L} {
                max-height: 7rem;
            }

            ${MQ.FROM_XL} {
                max-height: 8.75rem;
            }
        `,
    })}
`;
export const StyledButton = styled(Button)`
    && {
        grid-column: auto / span 4;
        margin-top: 0;

        ${MQ.FROM_M} {
            grid-column: auto / span 2;
        }

        ${MQ.FROM_L} {
            grid-column: auto / span 4;
        }
    }
`;

export const ReadMore = styled.div`
    align-self: flex-end;
    color: ${color};
    grid-area: readMore;
    grid-column: col-start 1 / span 4;
    padding-top: 4.8125rem;
    text-align: center;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 12;
    }

    ${theme("theme", {
        black: css`
            filter: var(--shadow-on-image);
        `,
    })};
`;

export const ReadMoreButton = styled(Button)`
    && {
        color: var(${color});

        &:focus-visible {
            outline: 7px solid var(--button-focus);
            outline-offset: -7px;
        }
    }
`;

export interface ContainerProps {
    readonly showArrowdown: boolean;
}
interface HeadingProps {
    readonly size?: string;
}
