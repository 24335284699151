import { styled } from "styled-components";
import theme from "styled-theming";

import { Container as GridContainer } from "style/components/Page";
import { styleHeadingL, styleHeadingXL } from "style/components/Typography";
import { GUTTER, columnGap } from "style/grid";
import { MQ } from "style/mediaQueries";
import { overlay } from "style/overlay";

export const PlayIconWrapper = styled.div`
    align-items: center;
    display: flex;
    inset: 0;
    justify-content: center;
    position: absolute;
    z-index: 2;
`;

export const TitleWrapper = styled(GridContainer)`
    ${columnGap}
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    padding: ${theme("size", {
        small: `0.875rem`,
        large: `1.75rem ${GUTTER.SM}`,
    })};
    z-index: 2;

    ${MQ.FROM_M} {
        grid-template-columns: ${theme("size", {
            small: "repeat(8, [col-start] 1fr)",
            large: "repeat(12, [col-start] 1fr)",
        })};
        padding: ${theme("size", {
            small: `0.875rem`,
            large: `3.5rem ${GUTTER.MD}`,
        })};
    }

    ${MQ.FROM_L} {
        grid-template-columns: ${theme("size", {
            small: "repeat(6, [col-start] 1fr)",
            large: "repeat(12, [col-start] 1fr)",
        })};
        padding: ${theme("size", {
            small: `1.3125rem`,
            large: `3.5rem ${GUTTER.MD}`,
        })};
    }

    ${MQ.FROM_XL} {
        grid-template-columns: ${theme("size", {
            small: "repeat(6, [col-start] 1fr)",
            large: "repeat(12, [col-start] 1fr)",
        })};
        padding: ${theme("size", {
            small: `1.75rem`,
            large: `4.375rem ${GUTTER.XL}`,
        })};
    }
`;

export const Title = styled.h2`
    && {
        ${theme("size", {
            large: styleHeadingXL,
            small: styleHeadingL,
        })}
        color: var(--text-on-image-primary);
        grid-column: col-start 1 / span 4;
        margin-top: 0;
        text-align: left;

        ${MQ.FROM_M} {
            grid-column: ${theme("size", {
                small: "col-start 1 / span 6",
                large: "col-start 1 / span 9",
            })};
        }

        ${MQ.FROM_L} {
            grid-column: ${theme("size", {
                small: "col-start 1 / span 5",
                large: "col-start 1 / span 6",
            })};
        }

        ${MQ.FROM_XL} {
            grid-column: ${theme("size", {
                small: "col-start 1 / span 5",
                large: "col-start 1 / span 6",
            })};
        }
    }
`;

export const VideoWrapper = styled.div`
    border: none;
    height: auto;
    width: 100%;
`;

export const VideoContainer = styled.div`
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    & > div,
    & > video {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
`;

export const Overlay = styled.div`
    inset: 0;
    position: absolute;
    z-index: 1;

    &::before {
        ${overlay("UpLeft")};
    }
`;

export const PlayIcon = styled.div`
    backdrop-filter: blur(2px);
    border: 1px solid var(--button-on-image-outline-border);
    border-radius: 0.25rem;
    padding: 0.375rem;
    transition: all 0.2s ease-in-out;
    z-index: 3;

    ${MQ.FROM_XL} {
        padding: 1.25rem;
    }
`;

export const ButtonWrapper = styled.div`
    align-items: center;
    background: var(--block-blue-default);
    border: none;
    color: var(--text-on-image-primary);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 100%;
    padding: 0;
    position: relative;
    width: 100%;

    &:focus-visible {
        ${PlayIcon} {
            outline: 7px solid var(--button-focus);
            outline-offset: 7px;
        }
    }

    &:hover {
        ${PlayIcon} {
            background: var(--button-on-image-outline-hover);
            border-color: var(--button-on-image-outline-hover);
            color: var(--text-on-neutral-primary);
        }
    }
`;
