import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import CallToActionProps from "./CallToActionProps";

const CallToAction = loadable(
    (): Promise<DefaultComponent<CallToActionProps>> =>
        import(/* webpackChunkName: "CallToAction" */ "./CallToAction"),
);
const CallToActionLoader = (props: CallToActionProps): ReactElement => (
    <CallToAction {...props} />
);
export default CallToActionLoader;
