import { styled, css } from "styled-components";
import theme from "styled-theming";

import Icon from "components/Icon";
import { default as NavLink } from "components/Link";
import {
    styleLabelM,
    styleQuoteL,
    styleQuoteM,
} from "style/components/Typography";
import { MQ } from "style/mediaQueries";

const linkColor = theme("theme", {
    lightgray: "--text-on-neutral-secondary",
    blue: "--text-on-blue-secondary",
    cyan: "--text-on-cyan-secondary",
});

const hoverColor = theme("theme", {
    lightgray: "--text-on-neutral-link",
    blue: "--text-on-blue-primary-alt",
    cyan: "--text-on-cyan-primary",
});

export const Container = styled.div`
    border-left: 1px solid var(--icon-decorative-coral);
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    padding-left: 1.75rem;

    ${MQ.FROM_M} {
        gap: 1.3125rem;
        padding-left: 2.1875rem;
    }

    ${MQ.FROM_XL} {
        gap: 1.75rem;
        padding-left: 2.625rem;
    }
`;

export const Quote = styled.div`
    ${theme("size", {
        small: css`
            ${styleQuoteM};
        `,
        large: css`
            ${styleQuoteL};
        `,
    })}
`;

export const LinkText = styled.div`
    ${styleLabelM};
    margin-right: 0.875rem;
    transition: margin-right 0.2s ease-in-out;
`;

export const IconArrow = styled(Icon)`
    margin-right: 0.875rem;
    transition: margin-left 0.2s ease-in-out;
`;

export const Link = styled(NavLink)`
    border-bottom: none;
    color: var(${linkColor});
    display: block;
    transition: color 0.2s ease-in-out;

    &:hover {
        color: var(${hoverColor});

        ${LinkText} {
            margin-right: 0;
        }

        ${IconArrow} {
            margin-left: 0.875rem;
        }
    }
`;
